<script>
import Swal from "sweetalert2";
import { ApiService } from "../../helpers/api.service.js";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { ref, defineAsyncComponent, watch } from "vue";
import Graficos from "@/components/graficos/index.vue";
import { setGrafico } from "./setGrafico.js";
import { useRoute } from "vue-router";
import MassActionsModals from '@/components/massActionsModals/index';

export default {
  setup() {
    const panels = ref([]);
    const filterPBI = []
    const idFilterActive = ref("");
    const JSONFilterActive = ref([]);
    const currentIndex = ref(0);
    const dados_consulta1 = ref(null);
    const loading = ref(true);
    const route = useRoute()

    watch(() => route.params.targetId, (id) => {
      panels.value = [];
      setPanel(id);
    });

    async function getPanels(id_programa) {
      const body = {
        id_programa: id_programa,
        condicao_adicional: "",
        lista_id_empresa:
          JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? [],
        navegador: false,
        ordenacao: null,
        quantidade_registros: 10000,
        pagina: 0,
        rota: "obtem_painel_usuario",
      };
      filterPBI.push({
        tabela_filtro: 'public vwbi_empresa',
        coluna_filtro: 'id_empresa',
        parametro_filtro: JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? []
      })
      return await ApiService({
        method: "POST",
        url: `/program`,
        data: body,
        headers: {
          authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      })
        .then((res) => {
          const data = res.data;
          if (!data.status) {
            Swal.fire({
              icon: "error",
              text: data.mensagem ? data.mensagem : data.erro,
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            });
            return false;
          }
          return data.retorno;
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error.response.data.mensagem ?? error.message,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          });
          return false;
        });
    }

    async function loadPanelData(index) {
      if (panels.value[index]?.tipo_programa === 4) {
        const dados_consulta = await getDadosConsultaPainel(
          panels.value[index].id_programa,
          panels.value[index].tipo_programa
        );
        if (dados_consulta) {
          panels.value[index]?.sis_consulta_painel?.forEach((card) => {
            const foundData = dados_consulta.dados.sis_consulta?.registros.find(
              (sc) => sc.id_consulta == card.id_consulta
            );
            card.dados_consulta = foundData ? foundData.dados_consulta : null;
            card.data_loaded = true;
          });
        } else {
          panels.value[index]?.sis_consulta_painel?.forEach((card) => {
            card.data_loaded = true; // Marcar como carregado para evitar spinner infinito
          });
        }
      }
    }

    const getDadosConsultaPainel = async (id_programa, tipo) => {
      if (tipo !== 4) {
        return null; // Retorna nulo se o tipo não for 4
      }
      const body = {
        id_programa: id_programa,
        condicao_adicional: "",
        lista_id_empresa:
          JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? [],
        navegador: false,
        ordenacao: null,
        quantidade_registros: 10000,
        pagina: 0,
        rota: "obtem_dados_consulta_painel",
      };

      try {
        const res = await ApiService({
          method: "POST",
          url: `/program`,
          data: body,
          headers: {
            authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        });

        const data = res.data;
        if (!data.status) {
          Swal.fire({
            icon: "error",
            text: data.mensagem ? data.mensagem : data.erro,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          });
          return null;
        }
        return data.retorno;
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: error.response.data.mensagem ?? error.message,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        });
        return null;
      }
    };

    const setPanel = async (id_programa) => {
      getPanels(id_programa).then(async (res) => {
        if (!res) return;

        panels.value = res.dados?.sis_painel?.registros || [];

        panels.value
          .filter((a) => a.tipo_programa == 4)
          .forEach((panel) => {
            if (panel.sis_consulta_painel) {
              panel.sis_consulta_painel.sort(
                (a, b) =>
                  a.configuracao_consulta.ordem_exibicao -
                  b.configuracao_consulta.ordem_exibicao
              );
              panel.sis_consulta_painel.forEach((card) => {
                card.data_loaded = false;
              });
            }
          });

        loading.value = false;

        if (!panels.value.length) return;

        if (panels.value[currentIndex.value].tipo_programa == 4) {
          dados_consulta1.value = await getDadosConsultaPainel(
            panels.value[currentIndex.value].id_programa,
            panels.value[currentIndex.value].tipo_programa
          );
          panels.value[currentIndex.value];
          panels.value[currentIndex.value]?.sis_consulta_painel?.forEach(
            (card) => {
              if (dados_consulta1.value?.dados?.sis_consulta?.registros) {
                card.dados_consulta =
                  dados_consulta1.value.dados.sis_consulta.registros.find(
                    (sc) => sc.id_consulta == card.id_consulta
                  )?.dados_consulta || null;
              }
              card.data_loaded = true;
            }
          );

          loading.value = false;
          await loadPanelData(0);
        }
      });
    }

    setPanel(route.params.targetId);

    return {
      panels,
      currentIndex,
      setGrafico,
      loading,
      idFilterActive,
      JSONFilterActive,
      filterPBI
    };
  },
  components: {
    Layout,
    PageHeader,
    Graficos,
    MassActionsModals,
    powerbi: defineAsyncComponent(() =>
      import("@/components/graficos/powerbi.vue")
    ),
  },
  computed: {
    statemode() {
      return this.$store.state.layout.mode;
    },
    statefilters() {
      return this.$store.state.chartConfig;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="panels[0]?.nome"  :tipo_programa="panels[0]?.tipo_programa" />
    <div v-if="loading" class="d-flex justify-content-center align-items-center" style="
        position: absolute;
        left: 249px;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      ">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Carregando...</span>
      </div>
    </div>
    <div v-else-if="!loading && panels.length" id="carouselExampleIndicators" class="carousel slide m-0 flex-1" data-bs-ride="carousel">
      <powerbi v-if="panels[0].tipo_programa == 7" :powerBIData="panels[0]" :id="panels[0].id_painel" :filterPBI="filterPBI">
      </powerbi>

      <div v-else-if="panels[0].tipo_programa == 4" class="row">
        <div v-for="card in panels[0].sis_consulta_painel" :key="card.id_consulta_painel" :class="[
          'col-lg-' + card.configuracao_consulta.colspan,
          'col-12',
          'd-flex flex-column justify-content-center align-items-center',
        ]" :style="{
          padding: '8px',
          overflowY: 'hidden',
          height:
            'calc(' +
            card.configuracao_consulta.rowspan +
            ' * ((60em - 80px) / 12))',
        }">
          <template v-if="card.configuracao_consulta">
            <template v-if="card.data_loaded">
              <Graficos :allConfigComponent="setGrafico(card, statemode)" />
            </template>
            <div v-else class="d-flex justify-content-center align-items-center" style="
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                  ">
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Carregando...</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <MassActionsModals />
  </Layout>
</template>
<style scoped>
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='grey'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='grey'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.carousel-nav-button {
  transition: transform 0.3s;
  /* Adiciona uma transição suave */
  padding: 5px 10px;
  /* Ajuste o padding conforme necessário */
}

.carousel-nav-button:hover .carousel-control-prev-icon,
.carousel-nav-button:hover .carousel-control-next-icon {
  transform: scale(1.2);
  /* Aumenta o tamanho do ícone ao passar o mouse */
}

.grafico-container {
  width: 100%;
}
</style>
